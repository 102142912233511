import React, { useEffect } from 'react';
import { View, Text, Card, Button, Colors } from 'react-native-ui-lib';
import { StyleSheet } from 'react-native';
import { PrimaryButton } from '../../../../../mycomponents/DynamicForm';
import { GlobalMasterStore } from '../../../../../models/PermissionStore';
import { api } from '../../../../../services/api';
import axios from 'axios';

const FeatureItem = ({ label, included }) => (
  <View row centerV marginB-5>
    <View
      style={[
        styles.checkmark,
        { backgroundColor: included ? '#e0e7ff' : '#f3f4f6' }
      ]}
    >
      {included && <Text style={{ color: '#4f46e5' }}>✓</Text>}
    </View>
    <Text style={[styles.featureText, { color: included ? '#000' : '#9ca3af' }]}>{label}</Text>
  </View>
);

const PricingCard = ({ title, price, description, features, buttonLabel, isPrimary, onButtonPress, disabled }) => (
  <Card style={[styles.card]}>
    <Text style={[styles.cardTitle, isPrimary && styles.primaryText]}>{title}</Text>
    <Text style={[styles.price, isPrimary && styles.primaryText]}>
      ${price}<Text style={styles.perMonth}>/month</Text>
    </Text>
    <Text style={[styles.description, isPrimary && styles.primaryText]}>{description}</Text>

    <View style={styles.featuresContainer}>
      {features.map((feature, index) => (
        <FeatureItem key={index} label={feature.label} included={feature.included} />
      ))}
    </View>

    <PrimaryButton
      label={buttonLabel}
      onPress={onButtonPress}
      disabled={disabled}
    //   style={[styles.button, isPrimary && styles.primaryButton]}
    //   onPress={onButtonPress}
    />
  </Card>
);

const SubscriptionPage = () => {
  const features = [
    'Browse Research Papers',
    'Save Research Papers',
    'AI Chat',
    'Ideation Tool',
    'Research Paper Outline',
    'Research Paper Builder',
    'Multiple Students',
    'View Students Progress',
    'Chat System Per Progress Basis',
  ];
  const email = GlobalMasterStore((state) => state.globalData.email);

  useEffect(() => {
    if (document.getElementById('razorpay-script')) {
      return;
    }

    const script = document.createElement('script')
    script.id = 'razorpay-script'
    script.src = 'https://checkout.razorpay.com/v1/checkout.js'

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const handleTopup = async () => {
    try {
      const response = await api.customRoute('shop/generateOrderIdRazorPay', {
        amount: parseFloat(10) * 100 * 85,
        currency: 'INR',
      });
      console.log('PaymentInitate', response)
      const data = response.data;

      const options = {
        key: data.key_id,
        currency: 'INR',
        amount: parseFloat(10) * 100 * 85, // Convert to INR
        order_id: data.id,
        handler: function (response) {
          axios.post('https://researchist-new-api.inkapps.io/add-points-to-email-by-password-post', {
            email: email,
            points: 10,
            increase: true,
            subscription_expiry_date: new Date().setMonth(new Date().getMonth() + 1),
            password: '4375rjgdofn4uhf4978',
          }).then(() => {
              window.location.reload();
          });
        },
      };

      const rzp = new Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };

  return (
    <>
      <View center flex>
        {/* <Text center marginT-30 marginL-20 marginB-20 text40>Choose a plan that works  2for you</Text> */}

        <View style={{
          position: "relative",
          width: "100%",
        }}>
          <View center>
            <View style={{
              backgroundColor: "white",
              marginBottom: 10,
              marginTop: 20,
              paddingHorizontal: 20,
            }}>
              <Text style={{
                fontSize: 12,
                fontWeight: 'bold',
                color: "#20303c",
                textTransform: "uppercase",
                textAlign: "center",
                letterSpacing: 2,
              }}>Choose a plan that works for you</Text>
            </View>
          </View>

          <View style={{
            width: "100%",
            height: 1,
            backgroundColor: "#20303c",
            position: "absolute",
            top: 28,
            left: 0,
            zIndex: -1,
            opacity: 0.05,
          }}></View>
        </View>
      </View>
      <View row style={styles.container}>
        <PricingCard
          title="Free"
          price="0"
          description="Get started for free"
          features={features.map(f => ({ label: f, included: ['Browse Research Papers', 'Save Research Papers'].includes(f) }))}
          buttonLabel="Current Plan"
          disabled={true}
          onButtonPress={() => console.log('Join Free Plan')}
        />

        <PricingCard
          title="Standard"
          price="10"
          description="The most popular plan."
          features={features.map(f => ({ label: f, included: !['Multiple Students', 'View Students Progress', 'Chat System Per Progress Basis'].includes(f) }))}
          buttonLabel="Subscribe"
          isPrimary={true}
          onButtonPress={() => {
            handleTopup();
          }}
        />

        <PricingCard
          title="Enterprise"
          price="Custom"
          description="Contact us for more information."
          features={features.map(f => ({ label: f, included: true }))}
          buttonLabel="Contact Us"
          onButtonPress={() => {
            window.location.href = 'mailto:ruzidtechlabs@gmail.com';
          }}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    flexWrap: 'wrap',
    paddingVertical: 20,
    paddingTop: 20,
  },
  card: {
    padding: 24,
    width: 300,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginHorizontal: 10,
  },
  primaryCard: {
    backgroundColor: '#002fcf17',
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  price: {
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  perMonth: {
    fontSize: 16,
    fontWeight: 'normal',
  },
  description: {
    marginBottom: 20,
  },
  primaryText: {
    color: 'black',
  },
  featuresContainer: {
    marginBottom: 20,
  },
  featureText: {
    marginLeft: 10,
  },
  checkmark: {
    width: 20,
    height: 20,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: 'auto',
  },
  primaryButton: {
    backgroundColor: 'black',
  },
});

export default SubscriptionPage;