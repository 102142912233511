// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, TextInput, ActivityIndicator, StyleSheet } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView, Typography, Avatar } from 'react-native-ui-lib';
import DynamicForm, { CustomDatePicker, CustomDropDown, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App, Dropdown, Segmented, Slider, DatePicker } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"
import moment from "moment"
import { set } from "mobx"
import axios from "axios"
import { Path, Svg } from "react-native-svg"
import { FlashList } from "@shopify/flash-list"
import dayjs from "dayjs"
import { Realtime } from 'ably';
import { RND } from "../../../../../models/accountancy/transaction"
import WalletTopup from "./WalletTopup"
import SubscriptionPage from "./Subscription"
const { RangePicker } = DatePicker;

const isDesktop = Dimensions.get('window').width >= 768;

const PaperListingScreen = observer(function PaperListingScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);
    const { route } = props;
    let queryParams = {};

    let params = route?.params ? route?.params : useParams();

    if (Platform.OS === 'web') {
        const fullUrl = new URL(window.location.href).search;
        const queryStringStart = fullUrl.indexOf('?');
        const queryString = fullUrl.substring(queryStringStart);

        const queries = queryString.substring(1);

        queries.split('&').forEach(pair => {
            const [key, value] = pair.split('=');
            queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
        });

        if (!params.ignoreQueryParams) {
            params = { ...params, ...queryParams }
        }
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [search, setSearch] = useState(queryParams.search || "");
    const [category, setCategory] = useState(queryParams.category_name || "");
    const [paperType, setPaperType] = useState([]);
    const [qualityType, setQualityType] = useState("");
    const [authors, setAuthors] = useState(queryParams.authors_name || "");
    const [year, setYear] = useState(queryParams.year || null);
    const [citationCounts, setCitationCounts] = useState(queryParams.minimum_citation_counts || null);

    const [password, setPassword] = useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [forgotOTP, setForgotOTP] = useState("");
    const [forgotPassword, setForgotPassword] = useState("");
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const [loading, setLoading] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortBy, setSortBy] = useState("relevance");
    const [pageIndex, setPageIndex] = useState(1);
    const [sortColumn, setSortColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [papers, setPapers] = useState([]);
    const [cartModal, setCartModal] = useState(false);
    const cart = GlobalMasterStore((state) => state.cart);
    const setCart = GlobalMasterStore((state) => state.setCart);
    const [chatModal, setChatModal] = useState(false);
    const [citationModal, setCitationModal] = useState(false);
    const [citationText, setCitationText] = useState("");
    const [chatPaperId, setChatPaperId] = useState(null);
    const [allLoaded, setAllLoaded] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [yearRange, setYearRange] = useState(queryParams.year ? [dayjs(queryParams.year, "YYYY"), dayjs(queryParams.year, "YYYY")] : []);
    const searchTimerRef = React.useRef(null);
    const email = GlobalMasterStore((state) => state.globalData.email);

    const handleYearChange = (value) => {
        setYearRange(value);
    };

    const onQueryParams = (params) => {
        if (params.pageIndex) {
            setPageIndex(params.pageIndex);
        }
        if (params.pageSize) {
            setPageSize(params.pageSize);
        }
        if (params.sortColumn || params.sortColumn == null) {
            setSortColumn(params.sortColumn);
        }
        if (params.sortDirection || params.sortDirection == null) {
            setSortDirection(params.sortDirection);
        }
    }

    const onCheckboxChange = (newSet) => {
        setIds([...newSet]);
    }

    const getPapersBySearch = async (reset = false, customYear = 0) => {
        let params = {
            searchTerm: search,
            page: pageIndex,
            limit: 10,
        };

        if (category) {
            params.category_name = category;
        }

        console.log(yearRange);

        if (yearRange && yearRange.length == 2) {
            params.min_year = yearRange[0] ? yearRange[0].format("YYYY") : 0;
            params.max_year = yearRange[1] ? yearRange[1].format("YYYY") : 0;
        }

        if (authors) {
            params.authors_name = authors;
        }

        if (citationCounts) {
            params.minimum_citation_counts = citationCounts;
        }

        if (paperType && paperType.length > 0) {
            params.type_crossref = paperType.join(",");
        }

        if (qualityType) {
            params.qualityType = qualityType;
        }

        setLoading(true);
        const response = await axios.get('https://researchist-new-api.inkapps.io/papers', {
            params: params
        });

        if (reset) {
            setPapers(response.data.papers);
        } else {
            setPapers([...papers, ...response.data.papers]);
        }

        setTotalResults(response.data.pagination.totalHits);

        if (response.data.papers.length == 0) {
            setAllLoaded(true);
        } else {
            setAllLoaded(false);
        }

        setLoading(false);
    }

    useEffect(() => {
        if (searchTimerRef.current) {
            clearTimeout(searchTimerRef.current);
        }

        searchTimerRef.current = setTimeout(() => {
            getPapersBySearch(true);
        }, 500);
    }, [category, year, authors, citationCounts, sortBy, yearRange, paperType, qualityType]);

    useEffect(() => {
        if (pageIndex > 1) {
            getPapersBySearch(false);
        }
    }, [pageIndex]);

    const [savedPaperIds, setSavedPaperIds] = useState([]);
    const [folders, setFolders] = useState([]);

    const getSavedPaperIds = async () => {
        const res = await api.customRoute("get-saved-paper-ids", {
            email_id: email,
        });

        setSavedPaperIds(res.savedPaperIds);
    }

    const getFolders = async () => {
        const res = await api.customRoute("get-folders", {
            email_id: email,
        });

        setFolders(res.folders);
    }

    useEffect(() => {
        getSavedPaperIds();
        getFolders();
    }, [email]);

    return (<View center style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View centerH flexG marginB-20
                    style={{
                        width: "100%",
                        height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                    }}
                >
                    <HeaderResearch navigationFn={navigationFn} showPaperCount={true} />

                    <View marginT-10 centerH row padding-20 style={{
                        width: "100%",
                        maxWidth: '100%',
                    }}>
                        <View row backgroundColor="white" padding-10 style={{
                            width: "100%",
                            borderRadius: 10,
                            backgroundColor: "#ffffff85",
                            borderRadius: 16,
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 4,
                            },
                            shadowOpacity: 0.1,
                            shadowRadius: 30,
                            elevation: 5,
                            backdropFilter: "blur(5px)",
                            WebkitBackdropFilter: "blur(5px)",
                            border: "1px solid rgba(255, 255, 255, 0.3)",
                        }}>
                            <View row flex centerV>
                                <Svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon"
                                    style={{
                                        width: "20px",
                                        verticalAlign: "middle",
                                        fill: "currentColor",
                                        overflow: "hidden",
                                    }}
                                    viewBox="0 0 1024 1024"
                                >
                                    <Path d="M204.8 341.33v136.54h68.27V409.6h68.26v341.34h-68.26v68.26h204.8v-68.26H409.6V409.6h68.27v68.27h68.26V341.33zm614.4-68.26V204.8c-41.001 0-77.373 18.463-102.4 47.104-25.027-28.631-61.399-47.104-102.4-47.104v68.27a68.27 68.27 0 0 1 68.27 68.27v204.8H614.4v68.26h68.27v68.26a68.28 68.28 0 0 1-68.27 68.28v68.26c41.001 0 77.373-18.463 102.4-47.104 25.027 28.641 61.399 47.104 102.4 47.104v-68.26a68.28 68.28 0 0 1-68.27-68.28V614.4h68.27v-68.26h-68.27v-204.8a68.27 68.27 0 0 1 68.27-68.27z" />
                                </Svg>
                                <View style={{
                                    flex: 1,
                                }}>
                                    <TextField
                                        value={search}
                                        placeholder="Search"
                                        placeholderTextColor="#00000045"
                                        style={{
                                            height: 40,
                                            color: "#000",
                                            marginLeft: 10,
                                            fontSize: 14,
                                            width: "100%",
                                        }}
                                        onChangeText={(value) => {
                                            setSearch(value);
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                getPapersBySearch(true);
                                            }
                                        }}
                                    />
                                </View>
                            </View>

                            <TouchableOpacity onPress={() => {
                                getPapersBySearch(true);
                            }}>
                                <View style={{
                                    backgroundColor: "#20303c",
                                    borderRadius: 80,
                                    padding: 10,
                                    marginLeft: 10,
                                }}>
                                    <Icon name="search-outline" width={16} height={16} fill="white" />
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View row paddingH-20 style={{
                        width: "100%",
                        marginTop: -20,
                    }}>
                        <View marginT-10 style={{
                            width: 280,
                            paddingVertical: 20,
                            position: 'sticky',
                            height: '85vh',
                            top: '0'
                        }}>
                            <View row centerV marginB-10>
                                <Svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    stroke="#20303c"
                                    strokeWidth={1.5}
                                    width={18}
                                    viewBox="0 0 24 24"
                                >
                                    <Path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                    />
                                </Svg>
                                <Text marginL-5 style={{
                                    fontSize: 14,
                                    color: "#20303c",
                                    fontWeight: 'bold',
                                }}>Publication Year</Text>
                            </View>

                            <View paddingH-10>
                                {/* <Slider
                                    range
                                    min={2010}
                                    max={2024}
                                    value={yearRange}
                                    onChange={handleYearChange}
                                    marks={{
                                        2010: '2010',
                                        2024: '2024',
                                    }}
                                /> */}

                                <RangePicker
                                    picker="year"
                                    value={yearRange}
                                    onChange={(value) => {
                                        setYearRange(value);
                                    }}
                                />
                            </View>

                            <View flex>
                                <View row centerV marginT-15 marginB-10>
                                    <Svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        stroke="#20303c"
                                        strokeWidth={1.5}
                                        width={18}
                                        viewBox="0 0 24 24"
                                    >
                                        <Path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                                        />
                                    </Svg>
                                    <Text marginL-5 style={{
                                        fontSize: 14,
                                        color: "#20303c",
                                        fontWeight: 'bold',
                                    }}>Category</Text>
                                </View>
                                <CustomSelect
                                    value={category}
                                    placeholder="Category"
                                    options={[
                                        {
                                            "id": "https://openalex.org/fields/27",
                                            "display_name": "Medicine"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/33",
                                            "display_name": "Social Sciences"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/22",
                                            "display_name": "Engineering"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/12",
                                            "display_name": "Arts and Humanities"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/17",
                                            "display_name": "Computer Science"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/13",
                                            "display_name": "Biochemistry, Genetics and Molecular Biology"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/11",
                                            "display_name": "Agricultural and Biological Sciences"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/23",
                                            "display_name": "Environmental Science"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/31",
                                            "display_name": "Physics and Astronomy"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/25",
                                            "display_name": "Materials Science"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/14",
                                            "display_name": "Business, Management and Accounting"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/36",
                                            "display_name": "Health Professions"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/20",
                                            "display_name": "Economics, Econometrics and Finance"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/32",
                                            "display_name": "Psychology"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/16",
                                            "display_name": "Chemistry"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/19",
                                            "display_name": "Earth and Planetary Sciences"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/28",
                                            "display_name": "Neuroscience"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/26",
                                            "display_name": "Mathematics"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/24",
                                            "display_name": "Immunology and Microbiology"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/18",
                                            "display_name": "Decision Sciences"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/21",
                                            "display_name": "Energy"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/29",
                                            "display_name": "Nursing"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/30",
                                            "display_name": "Pharmacology, Toxicology and Pharmaceutics"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/35",
                                            "display_name": "Dentistry"
                                        },
                                        {
                                            "id": "https://openalex.org/fields/15",
                                            "display_name": "Chemical Engineering"
                                        }
                                    ].map((item) => {
                                        return {
                                            label: item.display_name,
                                            value: item.id,
                                        }
                                    })}
                                    onChange={(value) => {
                                        setCategory(value);
                                    }}
                                    allowClear={true}
                                />

                                <View row centerV marginT-20 marginB-10>
                                    <Svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        fill="none"
                                        viewBox="0 0 20 20"
                                    >
                                        <Path
                                            fill="#20303c"
                                            d="M5.224 10.33c.424.043 1.356.043 1.356.64 0 .811-1.186 1.878-3.092 3.03-.593.385-1.821.982-1.821 1.793 0 .64.466 1.11 1.44.982.932-.128 2.372-.769 4.277-2.39 1.99-1.665 3.854-4.439 3.854-7.17 0-2.774-1.863-5.548-4.87-5.548-2.414 0-4.49 1.835-4.49 4.353 0 1.92 1.314 4.14 3.346 4.31Z"
                                        />
                                        <Path
                                            fill="#20303c"
                                            d="M11.178 11.53c.348.184.73.303 1.141.338.424.043 1.356.043 1.356.64 0 .81-1.186 1.878-3.092 3.03-.593.384-1.821.982-1.821 1.793 0 .64.466 1.11 1.44.981.932-.128 2.372-.768 4.277-2.39 1.99-1.664 3.854-4.438 3.854-7.17 0-2.774-1.863-5.548-4.87-5.548-.707 0-1.385.158-1.99.444a7.481 7.481 0 0 1 .91 3.567c0 1.524-.488 3.002-1.205 4.315Z"
                                        />
                                    </Svg>
                                    <Text
                                        marginL-5
                                        style={{
                                            fontSize: 14,
                                            color: "#000000",
                                            fontWeight: 'bold',
                                        }}>Min Citation Counts</Text>
                                </View>
                                <CustomNumberInput
                                    value={citationCounts}
                                    placeholder="Min Citation Counts"
                                    onChange={(value) => {
                                        setCitationCounts(value);
                                    }}
                                />

                                <View row centerV marginT-20 marginB-10>
                                    <Svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon"
                                        style={{
                                            width: 20,
                                        }}
                                        viewBox="0 0 1024 1024"
                                    >
                                        <Path
                                            fill="#20303c"
                                            d="M673.579 584.96c-11.734 0-25.046 0-39.168 2.005 46.848 33.963 79.573 78.806 79.573 139.392V827.35h242.347V726.357c0-94.122-188.587-141.397-282.752-141.397m-323.2 0c-94.123 0-282.795 47.275-282.795 141.397V827.35h565.59V726.357c0-94.122-188.63-141.397-282.795-141.397m0-80.81a121.173 121.173 0 0 0 121.216-121.174A121.173 121.173 0 0 0 350.42 261.803a121.173 121.173 0 0 0-121.216 121.173A121.173 121.173 0 0 0 350.38 504.149m323.2 0a121.173 121.173 0 0 0 121.173-121.173 121.173 121.173 0 0 0-121.173-121.173 121.173 121.173 0 0 0-121.174 121.173A121.173 121.173 0 0 0 673.58 504.149z"
                                        />
                                    </Svg>
                                    <Text
                                        marginL-5
                                        style={{
                                            fontSize: 14,
                                            color: "#000000",
                                            fontWeight: 'bold'
                                        }}>Author Name</Text>
                                </View>
                                <CustomTextField
                                    placeholder="Authors"
                                    value={authors}
                                    onChange={(value) => {
                                        setAuthors(value);
                                    }}
                                />

                                <View row centerV marginT-20 marginB-10>
                                    <Svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon"
                                        width={20}
                                        viewBox="0 0 1024 1024"
                                    >
                                        <Path d="M136.6 233.4h425.5c19.3 0 35-15.7 35-35s-15.7-35-35-35H136.6c-19.3 0-35 15.7-35 35s15.7 35 35 35zm0 212.1h205.6c19.3 0 35-15.7 35-35s-15.7-35-35-35H136.6c-19.3 0-35 15.7-35 35 0 19.4 15.7 35 35 35zm0 227.7h205.6c19.3 0 35-15.7 35-35s-15.7-35-35-35H136.6c-19.3 0-35 15.7-35 35s15.7 35 35 35zm755.3 122H136.6c-19.3 0-35 15.7-35 35s15.7 35 35 35h755.3c19.3 0 35-15.7 35-35 0-19.4-15.6-35-35-35zm-362.4-164c39.4 39.4 91.2 59.1 143 59.1 40.7 0 81.4-12.2 116.1-36.5l62.1 62.1c13.7 13.7 35.8 13.7 49.5 0 13.7-13.7 13.7-35.8 0-49.5L838 604.3c55.3-78.8 47.8-188.7-22.6-259.1-78.8-78.8-207.1-78.8-286 0-78.8 78.8-78.8 207.1.1 286zM579 394.7c25.8-25.8 59.6-38.7 93.5-38.7s67.7 12.9 93.5 38.7c51.6 51.6 51.6 135.4 0 187-51.6 51.6-135.4 51.6-187 0-51.6-51.6-51.6-135.5 0-187z" />
                                    </Svg>
                                    <Text
                                        marginL-5
                                        style={{
                                            fontSize: 14,
                                            color: "#000000",
                                            fontWeight: 'bold'
                                        }}>
                                        Paper Type
                                    </Text>
                                </View>

                                <CustomSelect
                                    value={paperType}
                                    placeholder="Paper Type"
                                    options={[
                                        { label: "Journal Article", value: "journal-article" },
                                        { label: "Posted Content", value: "posted-content" },
                                        { label: "Proceedings Article", value: "proceedings-article" },
                                        { label: "Proceedings", value: "proceedings" },
                                        { label: "Dataset", value: "dataset" },
                                        { label: "Dissertation", value: "dissertation" },
                                        { label: "Journal Issue", value: "journal-issue" },
                                        { label: "Report", value: "report" },
                                        { label: "Book Chapter", value: "book-chapter" },
                                        { label: "Book", value: "book" },
                                        { label: "Other", value: "other" },
                                        { label: "Journal", value: "journal" },
                                        { label: "Monograph", value: "monograph" },
                                        { label: "Component", value: "component" },
                                        { label: "Reference Entry", value: "reference-entry" },
                                        { label: "Report Series", value: "report-series" },
                                        { label: "Reference Book", value: "reference-book" },
                                        { label: "Standard", value: "standard" },
                                        { label: "Edited Book", value: "edited-book" },
                                        { label: "Proceedings Series", value: "proceedings-series" },
                                        { label: "Book Set", value: "book-set" },
                                        { label: "Peer Review", value: "peer-review" },
                                    ]}
                                    onChange={(value) => {
                                        setPaperType(value);
                                    }}
                                    multiple={true}
                                    allowClear={true}
                                />

                                <View row centerV marginT-20 marginB-10>
                                    <Svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                            width: 20,
                                            verticalAlign: "middle",
                                            fill: "currentColor",
                                            overflow: "hidden",
                                        }}
                                        viewBox="0 0 1024 1024"
                                    >
                                        <Path
                                            fill="#20303c"
                                            d="M304 106.667h416a106.667 106.667 0 0 1 88.768 47.53l139.968 210.07a85.333 85.333 0 0 1-7.51 104.32L591.404 858.453a106.667 106.667 0 0 1-158.806 0L82.773 468.565a85.333 85.333 0 0 1-7.509-104.298l139.947-210.07A106.667 106.667 0 0 1 304 106.667zm0 64a42.667 42.667 0 0 0-35.52 19.008l-139.947 210.09a21.333 21.333 0 0 0 1.878 26.07l349.802 389.888a42.667 42.667 0 0 0 63.531 0l349.824-389.888a21.333 21.333 0 0 0 1.877-26.07L755.5 189.675A42.667 42.667 0 0 0 720 170.667H304zm354.88 285.781a32 32 0 1 1 47.573 42.837L563.691 657.877c-23.915 26.582-65.494 30.208-94.08 8.555a66.859 66.859 0 0 1-9.302-8.533L317.547 499.285a32 32 0 1 1 47.573-42.837l142.763 158.613a2.88 2.88 0 0 0 .405.363c2.24 1.707 6.293 1.344 7.83-.363L658.88 456.448z"
                                        />
                                    </Svg>
                                    <Text
                                        marginL-5
                                        style={{
                                            fontSize: 14,
                                            color: "#000000",
                                            fontWeight: 'bold'
                                        }}>
                                        Quality Rating
                                    </Text>
                                </View>

                                <CustomSelect
                                    value={qualityType}
                                    placeholder="Quality Rating"
                                    options={[
                                        { label: "Q1", value: "Q1" },
                                        { label: "Q2", value: "Q2" },
                                        { label: "Q3", value: "Q3" },
                                        { label: "Q4", value: "Q4" },
                                    ]}
                                    onChange={(value) => {
                                        setQualityType(value);
                                    }}
                                    allowClear={true}
                                />
                            </View>
                        </View>

                        <View style={{
                            flex: 1,
                        }}>
                            <View row centerV spread style={{
                                padding: 20,
                                width: "100%",
                                paddingBottom: 10,
                            }}>
                                <Text style={{
                                    fontSize: 12,
                                }}>
                                    Papers Found - {totalResults}
                                </Text>
                                <View style={{
                                    width: 300
                                }}>
                                    <CustomSelect
                                        value={sortBy}
                                        placeholder="Sort By"
                                        options={[
                                            { label: "Relevance", value: "relevance" },
                                            { label: "Published Date", value: "publication_date" },
                                            { label: "Citation Count", value: "total_citation_counts" },
                                        ]}
                                        onChange={(value) => {
                                            setSortBy(value);
                                        }}
                                    />
                                </View>
                            </View>
                            <View row style={{
                                flexWrap: 'wrap',
                                paddingHorizontal: 20,
                                paddingVertical: 10,
                            }}>
                                {/* {papers.map((row, i) => <View
                                    key={"paper-" + i}
                                    style={{
                                        flexBasis: "100%",
                                        padding: 15,
                                    }}>
                                    <PublicationCard publication={row._source} savedPaperIds={savedPaperIds} folders={folders} getFolders={getFolders} getSavedPaperIds={getSavedPaperIds} message={message} />
                                </View>)} */}

                                <FlashList
                                    data={papers}
                                    renderItem={({ item, index }) => {
                                        return <PublicationCard publication={item._source} savedPaperIds={savedPaperIds} folders={folders} getFolders={getFolders} getSavedPaperIds={getSavedPaperIds} message={message} />

                                    }}
                                    keyExtractor={(item, index) => index.toString()}
                                    estimatedItemSize={300}
                                />
                            </View>

                            {loading ? <>
                                <View center style={{
                                    width: "100%",
                                    padding: 20
                                }}>
                                    <ActivityIndicator />
                                </View>
                            </> : !allLoaded && <View center style={{
                                width: "100%",
                                padding: 20
                            }}>
                                <SecondaryButton
                                    onPress={() => {
                                        setPageIndex(pageIndex + 1);
                                    }}
                                    label={"Load More"}
                                />
                            </View>}

                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>

        <CustomModal
            visible={citationModal}
            onClose={() => {
                setCitationModal(false);
            }}
            title="Citation"
            width={isDesktop ? 500 : "100%"}
            drawer={true}
        >
            <View padding-20>
                <Text>{citationText}</Text>
                <SecondaryButton style={{
                    marginTop: 10,
                }} onPress={() => {
                    navigator.clipboard.writeText(citationText);
                    message.success("Citation copied to clipboard!");
                }}>
                    <View row centerV>
                        <Icon name="copy-outline" width={16} height={16} fill="#20303C" />
                        <Text marginL-10>Copy</Text>
                    </View>
                </SecondaryButton>
            </View>
        </CustomModal>

        <CustomModal
            visible={chatModal}
            onClose={() => {
                setChatModal(false);
            }}
            title="Chat"
            width={isDesktop ? 500 : "100%"}
            drawer={true}
        >
            <View padding-20>
                <ChatComponent id={chatPaperId} />
            </View>
        </CustomModal>
    </View >)
})

export default PaperListingScreen

const $container = {
    flex: 1,
}

export const CartComponent = ({ cart, setCart, navigationFn }) => {
    if (!cart || cart.length == 0) return <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    }}>
        <Text style={{
            fontSize: 14,
            color: '#000',
        }}>No papers saved!</Text>
    </View>;

    const [suggestions, setSuggestions] = useState([]);
    const [page, setPage] = useState(1);
    const [allLoaded, setAllLoaded] = useState(false);

    const loadSuggestions = async () => {
        const response = await api.customRoute('Papers/action', {
            action: 'getSuggestions',
            papers: [...cart.map(item => item._id), ...suggestions.map(item => item._id)],
            page: page,
        });

        const newArr = [...suggestions, ...response.data];
        setSuggestions(newArr);

        if (response.data.length == 0) {
            setAllLoaded(true);
        } else {
            setAllLoaded(false);
        }
    }

    useEffect(() => {
        setPage(1);
        setAllLoaded(false);
    }, [cart]);

    useEffect(() => {
        loadSuggestions();
    }, [page]);

    return <>
        {cart && cart.length > 0 && cart.map((paper, i) => <View marginB-10 key={i} style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
        }}>
            <View style={{
                // flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                width: "100%"
            }}>
                <View style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                }}>
                    <Text style={{
                        fontSize: 14,
                        fontFamily: 'SourceSansProSemiBold',
                        color: '#414223',
                        textWrap: 'wrap',
                    }}>{paper.title}</Text>

                    {/* <Text marginB-10 style={{
                        fontSize: 13,
                        color: '#8b959c',
                        textWrap: 'wrap',
                        fontFamily: 'SourceSansProSemiBold'
                    }}>Future Scope Of Work</Text> */}

                    {/* {paper.future_scope_of_works && paper.future_scope_of_works.length > 0 && paper.future_scope_of_works.map((scope, i) => <Text key={i} style={{
                        fontSize: 13,
                        color: '#8b959c',
                        textWrap: 'wrap',
                        marginBottom: 5,
                    }}>
                        {i + 1}. {scope}</Text>)} */}

                    <View row marginB-10>
                        {/* <SecondaryButton style={{
                            marginTop: 10,
                            marginRight: 10,
                        }} onPress={() => {
                            navigationFn("chat-with-paper", {
                                id: paper._id,
                            });
                        }}>
                            <View row centerV>
                                <Icon name="file-text-outline" width={16} height={16} fill="#20303C" />
                                <Text color="#20303C" marginL-10>Select Paper</Text>
                            </View>
                        </SecondaryButton> */}
                        <SecondaryButton style={{
                            marginTop: 10,
                        }} onPress={() => {
                            setCart(cart.filter(item => item._id != paper._id));
                        }}>
                            <View row centerV>
                                <Icon name="shopping-bag-outline" width={16} height={16} fill="#b60505" />
                                <Text color="#b60505" marginL-10>Remove</Text>
                            </View>
                        </SecondaryButton>
                    </View>


                </View>
            </View>
        </View>)}

        <View marginV-10>
            <TouchableOpacity onPress={() => {
                if (cart && cart.length >= 5) {
                    navigationFn("topic-suggestion", {})
                }
            }}>
                <View row center padding-10 style={cart && cart.length >= 5 ? {
                    backgroundColor: '#20303C',
                    borderRadius: 5,
                } : {
                    backgroundColor: '#f5f5f5',
                    borderRadius: 5,
                }}>
                    {cart && cart.length >= 5 ? <Text marginR-10 style={{
                        fontSize: 12,
                        letterSpacing: 1,
                        color: '#fff',
                    }}>START YOUR JOURNEY</Text> : <Text marginR-10 style={{
                        fontSize: 12,
                        letterSpacing: 1,
                        color: '#8b959c',
                    }}>PLEASE ADD MORE PAPERS TO START YOUR JOURNEY</Text>}
                </View>
            </TouchableOpacity>
        </View>

        <View marginV-10>
            <Text h2>Suggested Papers</Text>
        </View>

        {suggestions && suggestions.length > 0 && suggestions.map((paper, i) => <View marginB-10 key={i} style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
        }}>
            <View style={{
                // flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                width: "100%"
            }}>
                <View style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                }}>
                    <Text style={{
                        fontSize: 14,
                        fontFamily: 'SourceSansProSemiBold',
                        color: '#414223',
                        textWrap: 'wrap',
                    }}>{paper.title}</Text>


                    <Text style={{
                        fontSize: 13,
                        color: '#8b959c',
                        textWrap: 'wrap',
                        marginBottom: 5,
                    }}>
                        {paper.description}
                    </Text>

                    <View row marginB-10>
                        <SecondaryButton style={{
                            marginTop: 10,
                            marginRight: 10,
                        }} onPress={() => {
                            setCart([...cart, {
                                _id: paper._id,
                                title: paper.title,
                                description: paper.description,
                                authors: paper.authors,
                                publishers: paper.publishers,
                                year_of_publication: paper.year_of_publication,
                                insights: paper.insights,
                                conclusions: paper.conclusions,
                                citation: paper.citation,
                                keywords: paper.keywords,
                                future_scope_of_works: paper.future_scope_of_works,
                            }]);

                            setSuggestions(suggestions.filter(item => item._id != paper._id));
                        }}>
                            <View row centerV>
                                <Icon name="file-text-outline" width={16} height={16} fill="#20303C" />
                                <Text color="#20303C" marginL-10>Save Paper</Text>
                            </View>
                        </SecondaryButton>
                    </View>
                </View>
            </View>
        </View>)}

        {!allLoaded ? <SecondaryButton
            style={{ marginTop: 20, marginBottom: 30 }}
            onPress={() => {
                setPage(page + 1);
            }}
            label={"Load More"}
        /> : <Text style={{
            fontSize: 14,
            color: '#000',
        }}>No more suggestions!</Text>}
    </>
}

const ChatComponent = ({ id }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [aiThinking, setAIThinking] = useState(false);

    const getAIResponse = async (message) => {
        setAIThinking(true);
        const response = await api.customRoute('Papers/action', {
            action: 'getAIResponse',
            message: message,
            paperId: id,
        });

        const messageData = {
            id: Date.now(),
            sender: 'AI',
            message: response.data,
            type: 'text',
            timestamp: new Date().toISOString(),
        };

        setAIThinking(false);
        setMessages((prevMessages) => [messageData, ...prevMessages]);
    }

    const sendMessage = async (text) => {
        if (newMessage.trim() || text) {
            const messageData = {
                id: Date.now(),
                sender: 'John', // Replace with the actual sender
                message: text ? text : newMessage,
                type: 'text',
                timestamp: new Date().toISOString(),
            };
            setMessages((prevMessages) => [messageData, ...prevMessages]);
            setNewMessage('');
            getAIResponse(newMessage.trim() || text);
        }
    };

    const sendAttachment = async (type) => {
        try {
            let result;
            if (type === 'image') {
                result = await ImagePicker.launchImageLibraryAsync({
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    allowsEditing: true,
                    aspect: [4, 3],
                    quality: 1,
                });
            } else if (type === 'document') {
                result = await DocumentPicker.getDocumentAsync({
                    type: '*/*',
                    copyToCacheDirectory: true,
                });
            } else if (type === 'audio') {
                result = await AudioPicker.getAudioAsync();
            }

            if (!result.cancelled) {
                const messageData = {
                    id: Date.now(),
                    sender: 'John', // Replace with the actual sender
                    message: result.uri,
                    type,
                    timestamp: new Date().toISOString(),
                };
                setMessages((prevMessages) => [...prevMessages, messageData]);
            }
        } catch (error) {
            console.error('Error sending attachment:', error);
        }
    };

    const renderMessage = ({ item }) => {
        const isMyMessage = item.sender === 'John';

        return (
            <View style={{ flexDirection: isMyMessage ? 'row-reverse' : 'row', marginVertical: 8 }}>
                <View
                    style={{
                        backgroundColor: isMyMessage ? '#DCF8C6' : '#E8E8E8',
                        borderRadius: 8,
                        padding: 8,
                        maxWidth: '70%',
                    }}
                >
                    {item.type === 'text' && <Text>{item.message}</Text>}
                    {item.type === 'image' && <Image source={{ uri: item.message }} style={{ width: 200, height: 200 }} />}
                    {item.type === 'document' && <Text>Document: {item.message}</Text>}
                    {item.type === 'audio' && <Text>Audio: {item.message}</Text>}
                    <Text style={{ fontSize: 10, color: 'gray', marginTop: 4 }}>
                        {moment(item.timestamp).format('h:mm a')}
                    </Text>
                </View>
            </View>
        );
    };

    useEffect(() => {
        const chatHistory = JSON.parse(localStorage.getItem('chatHistory_' + id)) || [];
        setMessages(chatHistory);
    }, [id]);

    useEffect(() => {
        localStorage.setItem('chatHistory_' + id, JSON.stringify(messages));
    }, [messages]);

    return (
        <View style={{ padding: 16, height: Dimensions.get('window').height - 100 }}>
            {messages.length != 0 && <FlatList
                style={{
                    flex: 1,
                }}
                data={messages}
                renderItem={renderMessage}
                keyExtractor={(item) => item.id.toString()}
                inverted
            />}

            {messages.length == 0 && <View center style={{
                flex: 1
            }}>
                <Image source={{ uri: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9f" }} style={{
                    width: "100%",
                    height: 300,
                    resizeMode: 'contain',
                }} />
            </View>}

            {aiThinking && <View center style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                <dotlottie-player src="https://lottie.host/514accb3-5931-46c9-98f2-5f3528856fa2/bWeYpvBviv.json" background="transparent" speed="1" style={{ width: 100, height: 100 }} loop autoplay></dotlottie-player>
            </View>}

            {messages.length == 0 && <>
                <TouchableOpacity
                    style={{
                        backgroundColor: '#DCF8C6',
                        padding: 12,
                        borderRadius: 8,
                        marginBottom: 8,
                    }}
                    onPress={() => {
                        sendMessage("Can you please summarize this research paper?");
                    }}
                >
                    <Text>Can you please summarize this research paper?</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={{
                        backgroundColor: '#DCF8C6',
                        padding: 12,
                        borderRadius: 8,
                    }}
                    onPress={() => {
                        sendMessage('What purpose does this research paper solve?');
                    }}
                >
                    <Text>What purpose does this research paper solve?</Text>
                </TouchableOpacity>
            </>}

            <View marginT-20 style={{ flexDirection: 'row', alignItems: 'center' }}>

                <CustomTextField
                    value={newMessage}
                    placeholder="Type a message..."
                    onChange={(value) => {
                        setNewMessage(value);
                    }}
                    suffixComponent={<PrimaryButton onPress={sendMessage} style={{ height: 38, marginLeft: 10 }}>
                        <View row>
                            <Icon name="paper-plane-outline" width={16} height={16} fill="white" />
                        </View>
                    </PrimaryButton>}
                />
            </View>
        </View>
    );
};

export const HeaderResearch = ({ navigationFn, showPaperCount }) => {
    const cart = GlobalMasterStore((state) => state.cart);
    const setCart = GlobalMasterStore((state) => state.setCart);
    const [cartModal, setCartModal] = useState(false);
    const [walletTopupModal, setWalletTopupModal] = useState(false);
    const [subscriptionModal, setSubscriptionModal] = useState(false);

    const email = GlobalMasterStore((state) => state.globalData.email);
    const name = GlobalMasterStore((state) => state.globalData.name);
    const points = GlobalMasterStore((state) => state.points);
    const setPoints = GlobalMasterStore((state) => state.setPoints);
    const [expiryDate, setExpiryDate] = useState(new Date().setMonth(new Date().getMonth() + 1));
    const currentDate = new Date();

    useEffect(() => {
        const ably = new Realtime({ key: 'x9wJ8g.wQTA_Q:VhLrbtIzPb8K_KMg6jMsFk1B2CUG2566pC8rAxkJVY4' });
        const channel = ably.channels.get('researchist');

        // await channel.publish('wallet-update', { email, new_balance });
        channel.subscribe('wallet-update', (message) => {
            // console.log('Message received:', message.data);
            console.log('Wallet update:', message.data);


            if (message.data.email == email) {
                setPoints(RND(message.data.new_balance));
            }
        });
    }, []);

    const getUser = async () => {
        try {
            const response = await api.customRoute("get-user", {
                email: email,
            });

            console.log("User data:", response);

            if (response.user.points >= 0) {
                setPoints(RND(response.user.points));
            }

            if (response.user.subscription_expiry_date) {
                setExpiryDate(new Date(response.user.subscription_expiry_date));
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        getUser();
    }, [email]);


    return <View centerH row padding-10 style={{
        width: "100%",
        maxWidth: '100%',
    }}>

        <View row centerV style={{
            width: "100%",
            minHeight: 50,
        }}>
            <TouchableOpacity
                onPress={() => {
                    navigationFn("", {})
                }}
                style={{
                    // padding: 10,
                    position: 'absolute',
                    top: -10,
                    left: -10,
                }}
            >
                <Image assetGroup="images" assetName="researchistlogolight" style={{
                    width: 180,
                    height: 70,
                }}
                    resizeMode="contain" />
            </TouchableOpacity>

            <View flex right row centerV>
                <SecondaryButton
                    style={{ marginLeft: 10, marginRight: 10, height: 38, borderRadius: 0, borderWidth: 0, backgroundColor: "transparent" }}
                    onPress={() => {
                        navigationFn("home", {})
                    }}
                >
                    <View row centerV>
                        <Icon name="search-outline" width={16} height={16} fill={"#414223"} />
                        <Text marginL-10
                            style={{
                                color: "#414223",
                                fontFamily: 'SourceSansProBold',
                            }}
                        >
                            Search Papers
                        </Text>
                    </View>
                </SecondaryButton>

                <View style={{
                    width: 1.5,
                    height: 38,
                    backgroundColor: "#20303c14",
                    marginHorizontal: 0,
                    borderRadius: 5,
                }}></View>

                <SecondaryButton
                    style={{ marginLeft: 10, marginRight: 10, height: 38, borderRadius: 0, borderWidth: 0, backgroundColor: "transparent" }}
                    onPress={() => {
                        navigationFn("library", {})
                    }}
                >
                    <View row centerV>
                        {/* <Icon name="book-outline" width={16} height={16} fill={"#414223"} /> */}

                        <Svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            stroke="#414223"
                            strokeWidth={1.5}
                            viewBox="0 0 24 24"
                            width={18}
                        >
                            <Path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                            />
                        </Svg>
                        <Text marginL-10
                            style={{
                                color: "#414223",
                                fontFamily: 'SourceSansProBold',
                            }}
                        >Manage reference</Text>
                    </View>
                </SecondaryButton>

                <View style={{
                    width: 1.5,
                    height: 38,
                    backgroundColor: "#20303c14",
                    marginHorizontal: 0,
                    borderRadius: 5,
                }}></View>

                <SecondaryButton
                    style={{ marginLeft: 10, marginRight: 10, height: 38, borderRadius: 0, borderWidth: 0, backgroundColor: "transparent" }}
                    onPress={() => {
                        navigationFn("my-work", {})
                    }}
                >
                    <View row centerV>
                        {/* <Icon name="monitor-outline" width={16} height={16} fill={cart?.length >= 5 ? "#0f9b23" : "#414223"} /> */}

                        <Svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            stroke="#414223"
                            strokeWidth={1.5}
                            className="size-6"
                            viewBox="0 0 24 24"
                            width={20}
                        >
                            <Path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84 51.39 51.39 0 0 0-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
                            />
                        </Svg>
                        <Text marginL-10
                            style={{
                                color: cart?.length >= 5 ? "#0f9b23" : "#414223",
                                fontFamily: 'SourceSansProBold',
                            }}
                        >Ideation</Text>
                    </View>
                </SecondaryButton>

                <View style={{
                    width: 1.5,
                    height: 38,
                    backgroundColor: "#20303c14",
                    marginHorizontal: 0,
                    borderRadius: 5,
                }}></View>

                <SecondaryButton
                    style={{ marginLeft: 10, marginRight: 10, height: 38, borderRadius: 0, borderWidth: 0, backgroundColor: "transparent" }}
                    onPress={() => {
                        window.open("https://app.loopedin.io/researchist#/ideas", "_blank");
                    }}
                >
                    <View row centerV>
                        <Svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            stroke="#414223"
                            strokeWidth={1.5}
                            viewBox="0 0 24 24"
                            width={18}
                        >
                            <Path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                            />
                        </Svg>
                        <Text marginL-10
                            style={{
                                color: cart?.length >= 5 ? "#0f9b23" : "#414223",
                                fontFamily: 'SourceSansProBold',
                            }}
                        >Feedback</Text>
                    </View>
                </SecondaryButton>

                <SecondaryButton
                    style={{ marginLeft: 10, marginRight: 10, height: 38, borderRadius: 0, borderWidth: 0, backgroundColor: "transparent" }}
                    onPress={() => {
                        window.open("https://app.loopedin.io/researchist#/help-desk", "_blank");
                    }}
                >
                    <View row centerV>
                        {/* <Svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            stroke="#414223"
                            strokeWidth={1.5}
                            viewBox="0 0 24 24"
                            width={18}
                        >
                            <Path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                            />
                        </Svg> */}

                        <Svg
                            style={{
                                width: 18,
                                height: 18,
                                verticalAlign: "middle",
                                fill: "#414223",
                            }}
                            viewBox="0 0 1024 1024"
                        >
                            <Path d="M512 957.44c-199.68 0-302.08 0-373.76-71.68S66.56 711.68 66.56 512s0-302.08 71.68-373.76S312.32 66.56 512 66.56s302.08 0 373.76 71.68S957.44 312.32 957.44 512s0 302.08-71.68 373.76S711.68 957.44 512 957.44zm0-814.08c-179.2 0-271.36 0-317.44 46.08-51.2 51.2-51.2 143.36-51.2 322.56s0 271.36 46.08 317.44c46.08 46.08 138.24 46.08 317.44 46.08s271.36 0 317.44-46.08C870.4 783.36 870.4 691.2 870.4 512s0-271.36-46.08-317.44c-40.96-51.2-133.12-51.2-312.32-51.2zm0 471.04c-20.48 0-40.96-15.36-40.96-40.96v-20.48c0-46.08 30.72-81.92 56.32-112.64 10.24-10.24 15.36-20.48 25.6-30.72 5.12-5.12 5.12-15.36 5.12-25.6 0-25.6-20.48-46.08-46.08-46.08S465.92 358.4 465.92 384c0 20.48-15.36 40.96-40.96 40.96s-35.84-20.48-35.84-40.96c0-66.56 56.32-122.88 122.88-122.88S634.88 317.44 634.88 384c0 25.6-5.12 46.08-20.48 66.56-10.24 10.24-20.48 25.6-30.72 35.84-20.48 25.6-35.84 46.08-35.84 66.56v20.48c5.12 25.6-15.36 40.96-35.84 40.96zm0 163.84c-30.72 0-51.2-20.48-51.2-51.2s20.48-51.2 51.2-51.2 51.2 20.48 51.2 51.2-20.48 51.2-51.2 51.2z" />
                        </Svg>
                        <Text marginL-10
                            style={{
                                color: cart?.length >= 5 ? "#0f9b23" : "#414223",
                                fontFamily: 'SourceSansProBold',
                            }}
                        >Help Desk</Text>
                    </View>
                </SecondaryButton>

                <View style={{
                    width: 1.5,
                    height: 38,
                    backgroundColor: "#20303c14",
                    marginHorizontal: 0,
                    borderRadius: 5,
                }}></View>

                <SecondaryButton
                    style={{ marginLeft: 10, marginRight: 10, height: 38, borderRadius: 0, borderWidth: 0, backgroundColor: "transparent" }}
                    onPress={() => {
                        setWalletTopupModal(true);
                    }}
                >
                    <View row centerV>
                        <Image source={{
                            uri: "https://cdn-icons-png.flaticon.com/128/17301/17301413.png"
                        }} style={{
                            width: 24,
                            height: 24,
                            borderRadius: 15,
                        }}
                            resizeMode="cover" />
                        <Text marginL-10
                            style={{
                                color: cart?.length >= 5 ? "#0f9b23" : "#414223",
                                fontFamily: 'SourceSansProBold',
                            }}
                        >{(points || 0)*10} Coins</Text>
                    </View>
                </SecondaryButton>

                {expiryDate < currentDate && <>
                    <View style={{
                        width: 1.5,
                        height: 38,
                        backgroundColor: "#20303c14",
                        marginHorizontal: 0,
                        borderRadius: 5,
                    }}></View>

                    <PrimaryButton
                        style={{ marginLeft: 10, marginRight: 10, height: 38 }}
                        onPress={() => {
                            setSubscriptionModal(true);
                        }}
                        label={"Subscribe Now"}
                    />
                </>}

                <CustomModal
                    visible={walletTopupModal}
                    onClose={() => {
                        setWalletTopupModal(false);
                    }}
                    title="Wallet Topup"
                    width={460}
                >
                    <WalletTopup onTopup={() => {
                        console.log("Topup done");
                    }} />
                </CustomModal>

                <CustomModal
                    visible={subscriptionModal}
                    onClose={() => {
                        setSubscriptionModal(false);
                    }}
                    title=""
                    width={1040}
                >
                    <SubscriptionPage />
                </CustomModal>

                <View style={{
                    width: 1.5,
                    height: 38,
                    backgroundColor: "#20303c14",
                    marginHorizontal: 0,
                    borderRadius: 5,
                    marginRight: 20
                }}></View>
            </View>


            <CustomDropDown
                options={[

                ]}
                value={null}
                onChange={(option) => {
                    const value = option.value;

                    if (value == "logout") {
                        window.localStorage.clear();
                        window.location.reload();
                    } else if (value == "profile") {
                        navigationFn("profile", {})
                    }
                }}
                dropdownRender={(menu) => (
                    <Card style={{
                        minWidth: 350
                    }}>
                        <View paddingH-10 paddingB-15 marginV-10 style={{
                            borderBottomWidth: 1,
                            borderBottomColor: "#20303c14",
                        }}>
                            <View style={{
                                flexDirection: 'row',
                                marginRight: 50,
                            }}>
                                <Avatar source={{ uri: 'https://lh3.googleusercontent.com/-cw77lUnOvmI/AAAAAAAAAAI/AAAAAAAAAAA/WMNck32dKbc/s181-c/104220521160525129167.jpg' }} label={"JD"} />
                                <View style={{
                                    marginLeft: 10,
                                }}>
                                    <Text style={{
                                        marginBottom: 0,
                                        color: "#000",
                                        fontWeight: 'bold',
                                    }}>
                                        {name}
                                    </Text>
                                    <Text style={{
                                        marginBottom: 0,
                                        color: "#8b959c",
                                    }}>
                                        {email}
                                    </Text>
                                </View>
                            </View>

                        </View>

                        <TouchableOpacity onPress={() => {
                            navigationFn("profile", {})
                        }}>
                            <View paddingH-10 paddingB-10 marginV-5 style={{
                                borderBottomWidth: 1,
                                borderBottomColor: "#20303c14",
                            }}>
                                <View row padding-5>
                                    <Icon name="person-outline" width={16} height={16} fill="#20303c" />
                                    <Text marginL-5 style={{ color: "#20303c" }}>Profile</Text>
                                </View>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => {
                            window.localStorage.clear();
                            window.location.reload();
                        }}>
                            <View paddingH-10 paddingB-10 marginV-5>
                                <View row padding-5>
                                    <Icon name="log-out-outline" width={16} height={16} fill="#20303c" />
                                    <Text marginL-5 style={{ color: "#20303c" }}>Logout</Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                    </Card>
                )}
            >
                <View row centerV style={{
                    marginLeft: 10,
                    marginRight: 10,
                }}>
                    <Image source={{
                        uri: "https://cdn-icons-png.flaticon.com/128/1999/1999625.png"
                    }} style={{
                        width: 30,
                        height: 30,
                        borderRadius: 15,
                    }}
                        resizeMode="cover" />
                </View>
            </CustomDropDown>
        </View>

        <CustomModal
            visible={cartModal}
            onClose={() => {
                setCartModal(false);
            }}
            title="Saved Papers"
            width={isDesktop ? 500 : "100%"}
            drawer={true}
        >
            <View padding-20>
                <CartComponent cart={cart} setCart={setCart} navigationFn={navigationFn} />
            </View>
        </CustomModal>
    </View>
}




const OAStatusBadge = ({ total_score }) => {
    const getColor = () => {
        if (total_score >= 0 && total_score <= 30) {
            return '#b23737';
        } else if (total_score > 30 && total_score <= 50) {
            return '#cfa514';
        } else if (total_score > 50 && total_score <= 70) {
            return '#1787cf';
        } else if (total_score > 70 && total_score <= 100) {
            return '#09931a';
        }
    };

    const getTier = () => {
        if (total_score > 0 && total_score <= 30) {
            return 'Q4';
        } else if (total_score > 30 && total_score <= 50) {
            return 'Q3';
        } else if (total_score > 50 && total_score <= 70) {
            return 'Q2';
        } else if (total_score > 70 && total_score <= 100) {
            return 'Q1';
        }
    };

    return (
        <View style={[styles.badge, { backgroundColor: getColor(), height: 76, minWidth: 100, padding: 8 }]}>
            <View backgroundColor="#ffffff1a" center flex style={{
                borderRadius: 5,
            }}>
                <Text style={styles.badgeText}>{getTier()}</Text>
                {/* <Svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        width: 20,
                        verticalAlign: "middle",
                        fill: "currentColor",
                        overflow: "hidden",
                    }}
                    viewBox="0 0 1024 1024"
                >
                    <Path
                        fill="#ffffff"
                        d="M304 106.667h416a106.667 106.667 0 0 1 88.768 47.53l139.968 210.07a85.333 85.333 0 0 1-7.51 104.32L591.404 858.453a106.667 106.667 0 0 1-158.806 0L82.773 468.565a85.333 85.333 0 0 1-7.509-104.298l139.947-210.07A106.667 106.667 0 0 1 304 106.667zm0 64a42.667 42.667 0 0 0-35.52 19.008l-139.947 210.09a21.333 21.333 0 0 0 1.878 26.07l349.802 389.888a42.667 42.667 0 0 0 63.531 0l349.824-389.888a21.333 21.333 0 0 0 1.877-26.07L755.5 189.675A42.667 42.667 0 0 0 720 170.667H304zm354.88 285.781a32 32 0 1 1 47.573 42.837L563.691 657.877c-23.915 26.582-65.494 30.208-94.08 8.555a66.859 66.859 0 0 1-9.302-8.533L317.547 499.285a32 32 0 1 1 47.573-42.837l142.763 158.613a2.88 2.88 0 0 0 .405.363c2.24 1.707 6.293 1.344 7.83-.363L658.88 456.448z"
                    />
                </Svg> */}
            </View>
        </View>
    );
};

const formatTypeCrossref = (type) => {
    return type
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const CitationComponent = ({ citationData }) => {
    const [citationFormat, setCitationFormat] = useState('APA');
    const [selectedSegment, setSelectedSegment] = useState("APA");

    const formatCitation = () => {
        if (citationFormat === 'BibTeX') {
            let text = ``;

            text += `@${citationData.authorships[0].display_name.split(' ')[0].toLowerCase()}${citationData.publication_year}${citationData.title.split(' ')[0].toLowerCase()}\n`;

            text += `title={${citationData.title}}\n`;

            text += `author={${citationData.authorships.map(a => a.display_name).join(' and ')}}\n`;

            if (citationData.primary_location.source.display_name)
                text += `journal={${citationData.primary_location.source.display_name}}\n`;

            if (citationData.biblio.volume)
                text += `volume={${citationData.biblio.volume}}\n`;

            if (citationData.biblio.issue)
                text += `number={${citationData.biblio.issue}}\n`;

            if (citationData.biblio.first_page)
                text += `pages={${citationData.biblio.first_page}--${citationData.biblio.last_page}}\n`;

            text += `year={${citationData.publication_year}}\n`;

            if (citationData.primary_location.source.host_organization_name)
                text += `publisher={${citationData.primary_location.source.host_organization_name}}`;

            return text;
        }

        if (citationFormat === 'APA') {
            let text = ``;

            text += `${citationData.authorships.map(a => a.display_name).join(', ')}. (${citationData.publication_year}). ${citationData.title}. ${citationData.primary_location.source.display_name}. ${citationData.biblio.volume ? citationData.biblio.volume : ''}${citationData.biblio.issue ? `(${citationData.biblio.issue})` : ''}${citationData.biblio.first_page ? `, ${citationData.biblio.first_page}--${citationData.biblio.last_page}` : ''}.`;

            return text;
        }

        if (citationFormat === 'MLA') {
            let text = ``;

            text += `${citationData.authorships.map(a => a.display_name).join(', ')} et al. "${citationData.title}." ${citationData.primary_location.source.display_name}, ${citationData.biblio.volume ? citationData.biblio.volume : ''}${citationData.biblio.issue ? `(${citationData.biblio.issue})` : ''} (${citationData.publication_year}).`;

            return text;
        }

        if (citationFormat === 'Chicago') {
            let text = ``;

            text += `${citationData.authorships.map(a => a.display_name).join(', ')}. "${citationData.title}." ${citationData.primary_location.source.display_name} ${citationData.biblio.volume ? citationData.biblio.volume : ''}${citationData.biblio.issue ? `(${citationData.biblio.issue})` : ''} (${citationData.publication_year}).`;

            return text;
        }

        if (citationFormat === 'Harvard') {
            let text = ``;

            text += `${citationData.authorships.map(a => a.display_name).join(', ')}, ${citationData.publication_year}. ${citationData.title}. ${citationData.primary_location.source.display_name} ${citationData.biblio.volume ? citationData.biblio.volume : ''}${citationData.biblio.issue ? `(${citationData.biblio.issue})` : ''}.`;

            return text;
        }


        return 'Citation format not implemented';
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(formatCitation());
    };

    return (
        <View>
            <Segmented
                options={["APA", "MLA", "Chicago", "Harvard", "BibTeX"]}
                selectedSegment={selectedSegment}
                onChange={(value) => {
                    setSelectedSegment(value);
                    setCitationFormat(value);
                }}
            />
            <View>
                <Text
                    style={{
                        marginVertical: 10,
                        padding: 10,
                        backgroundColor: '#f5f5f5',
                        color: "#23536d",
                        borderRadius: 5,
                        fontFamily: 'JetBrainsMonoLight',
                        fontSize: 12,
                        letterSpacing: 0.5,
                    }}
                >
                    {formatCitation()}
                </Text>
                <View row spread marginT-10>
                    <Button label="Copy" onPress={copyToClipboard} />
                </View>
            </View>
        </View>
    );
};

export const PublicationCard = ({ publication, savedPaperIds, folders, getFolders, getSavedPaperIds, message }) => {
    const [expanded, setExpanded] = useState(false);
    const [showAddToLibrary, setShowAddToLibrary] = useState(false);
    const [showCiteModal, setShowCiteModal] = useState(false);
    const [folderName, setFolderName] = useState('');
    const email = GlobalMasterStore((state) => state.globalData.email);

    return (<>
        <Card style={styles.card}>
            <View style={styles.header}>
                <OAStatusBadge total_score={publication.total_score} />
                <View style={styles.titleContainer}>
                    <View row spread top style={{
                        width: "100%",
                    }}>
                        <View row top flex>
                            <Text style={[
                                styles.title, {
                                    flexWrap: 'wrap',
                                }]}
                                numberOfLines={2}
                                ellipsizeMode="tail"
                            >{publication.title}</Text>
                        </View>
                        <TouchableOpacity onPress={() => {
                            setShowAddToLibrary(true);
                        }} style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 5,
                            paddingHorizontal: 10,
                            marginLeft: 10,
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: "#d7e0e6",
                            minWidth: 100,
                        }}>
                            <View row centerV>
                                <Icon name="plus-circle" width={16} height={16} fill="#0e5f9c" />
                                <Text marginL-5 marginB-1 style={{
                                    color: "#0e5f9c",
                                    fontFamily: 'SourceSansProSemiBold',
                                }}>Add To Folder</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            setShowCiteModal(true);
                        }} style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 5,
                            paddingHorizontal: 10,
                            marginLeft: 10,
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: "#d7e0e6",
                            minWidth: 100,
                        }}>
                            <View row centerV>
                                <Svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <Path
                                        fill="#0e5f9c"
                                        d="M5.224 10.33c.424.043 1.356.043 1.356.64 0 .811-1.186 1.878-3.092 3.03-.593.385-1.821.982-1.821 1.793 0 .64.466 1.11 1.44.982.932-.128 2.372-.769 4.277-2.39 1.99-1.665 3.854-4.439 3.854-7.17 0-2.774-1.863-5.548-4.87-5.548-2.414 0-4.49 1.835-4.49 4.353 0 1.92 1.314 4.14 3.346 4.31Z"
                                    />
                                    <Path
                                        fill="#0e5f9c"
                                        d="M11.178 11.53c.348.184.73.303 1.141.338.424.043 1.356.043 1.356.64 0 .81-1.186 1.878-3.092 3.03-.593.384-1.821.982-1.821 1.793 0 .64.466 1.11 1.44.981.932-.128 2.372-.768 4.277-2.39 1.99-1.664 3.854-4.438 3.854-7.17 0-2.774-1.863-5.548-4.87-5.548-.707 0-1.385.158-1.99.444a7.481 7.481 0 0 1 .91 3.567c0 1.524-.488 3.002-1.205 4.315Z"
                                    />
                                </Svg>
                                <Text marginL-5 marginB-1 style={{
                                    color: "#0e5f9c",
                                    fontFamily: 'SourceSansProSemiBold',
                                }}>Cite</Text>
                            </View>
                        </TouchableOpacity>
                    </View>

                    <View row marginT-5>
                        <View row marginR-10 centerV>
                            <Svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                stroke="#20303c"
                                strokeWidth={1.5}
                                width={18}
                                viewBox="0 0 24 24"
                            >
                                <Path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                />
                            </Svg>
                            <Text marginT-1 style={styles.citations} marginL-5>{publication.publication_year}</Text>
                        </View>
                        <View row marginR-10 centerV>
                            <Svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon"
                                width={20}
                                viewBox="0 0 1024 1024"
                            >
                                <Path d="M136.6 233.4h425.5c19.3 0 35-15.7 35-35s-15.7-35-35-35H136.6c-19.3 0-35 15.7-35 35s15.7 35 35 35zm0 212.1h205.6c19.3 0 35-15.7 35-35s-15.7-35-35-35H136.6c-19.3 0-35 15.7-35 35 0 19.4 15.7 35 35 35zm0 227.7h205.6c19.3 0 35-15.7 35-35s-15.7-35-35-35H136.6c-19.3 0-35 15.7-35 35s15.7 35 35 35zm755.3 122H136.6c-19.3 0-35 15.7-35 35s15.7 35 35 35h755.3c19.3 0 35-15.7 35-35 0-19.4-15.6-35-35-35zm-362.4-164c39.4 39.4 91.2 59.1 143 59.1 40.7 0 81.4-12.2 116.1-36.5l62.1 62.1c13.7 13.7 35.8 13.7 49.5 0 13.7-13.7 13.7-35.8 0-49.5L838 604.3c55.3-78.8 47.8-188.7-22.6-259.1-78.8-78.8-207.1-78.8-286 0-78.8 78.8-78.8 207.1.1 286zM579 394.7c25.8-25.8 59.6-38.7 93.5-38.7s67.7 12.9 93.5 38.7c51.6 51.6 51.6 135.4 0 187-51.6 51.6-135.4 51.6-187 0-51.6-51.6-51.6-135.5 0-187z" />
                            </Svg>
                            <Text style={styles.citations} marginL-5>{formatTypeCrossref(publication.type_crossref)}</Text>
                        </View>
                        <View row marginR-10 centerV>
                            <Svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <Path
                                    fill="#20303c"
                                    d="M5.224 10.33c.424.043 1.356.043 1.356.64 0 .811-1.186 1.878-3.092 3.03-.593.385-1.821.982-1.821 1.793 0 .64.466 1.11 1.44.982.932-.128 2.372-.769 4.277-2.39 1.99-1.665 3.854-4.439 3.854-7.17 0-2.774-1.863-5.548-4.87-5.548-2.414 0-4.49 1.835-4.49 4.353 0 1.92 1.314 4.14 3.346 4.31Z"
                                />
                                <Path
                                    fill="#20303c"
                                    d="M11.178 11.53c.348.184.73.303 1.141.338.424.043 1.356.043 1.356.64 0 .81-1.186 1.878-3.092 3.03-.593.384-1.821.982-1.821 1.793 0 .64.466 1.11 1.44.981.932-.128 2.372-.768 4.277-2.39 1.99-1.664 3.854-4.438 3.854-7.17 0-2.774-1.863-5.548-4.87-5.548-.707 0-1.385.158-1.99.444a7.481 7.481 0 0 1 .91 3.567c0 1.524-.488 3.002-1.205 4.315Z"
                                />
                            </Svg>
                            <Text style={styles.citations} marginL-5>Cited by: {publication.cited_by_count}</Text>
                        </View>
                        <View row marginR-10 centerV>
                            <Svg
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={1.5}
                                width={16}
                                viewBox="0 0 24 24"
                            >
                                <Path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25"
                                />
                            </Svg>
                            <Text style={styles.citations} marginL-5>References: {publication.referenced_works?.length || 0}</Text>
                        </View>

                        {publication.primary_location && publication.primary_location.landing_page_url && <TouchableOpacity
                            onPress={() => {
                                window.open(publication.primary_location.landing_page_url, '_blank');
                            }}
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 5,
                                paddingRight: 10,
                                marginLeft: 10,
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: "#dcdcdc80",
                            }}
                        >
                            <Icon name="external-link" width={16} height={16} fill="#20303c" />
                            <Text marginL-5>View</Text>
                        </TouchableOpacity>}
                        {publication.primary_location && publication.primary_location.pdf_url && <TouchableOpacity
                            onPress={() => {
                                window.open(publication.primary_location.pdf_url, '_blank');
                            }}
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 5,
                                paddingHorizontal: 10,
                                marginLeft: 10,
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: "#dcdcdc80",
                            }}
                        >
                            <Icon name="file-text-outline" width={16} height={16} fill="#20303c" />
                            <Text marginL-5>PDF</Text>
                        </TouchableOpacity>}
                    </View>
                </View>
            </View>

            <View style={styles.authors} row centerV marginT-2>
                <View style={{
                    width: 20,
                    minWidth: 20,
                }}>
                    <Svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        style={{
                            width: 20,
                        }}
                        viewBox="0 0 1024 1024"
                    >
                        <Path
                            fill="#20303c"
                            d="M673.579 584.96c-11.734 0-25.046 0-39.168 2.005 46.848 33.963 79.573 78.806 79.573 139.392V827.35h242.347V726.357c0-94.122-188.587-141.397-282.752-141.397m-323.2 0c-94.123 0-282.795 47.275-282.795 141.397V827.35h565.59V726.357c0-94.122-188.63-141.397-282.795-141.397m0-80.81a121.173 121.173 0 0 0 121.216-121.174A121.173 121.173 0 0 0 350.42 261.803a121.173 121.173 0 0 0-121.216 121.173A121.173 121.173 0 0 0 350.38 504.149m323.2 0a121.173 121.173 0 0 0 121.173-121.173 121.173 121.173 0 0 0-121.173-121.173 121.173 121.173 0 0 0-121.174 121.173A121.173 121.173 0 0 0 673.58 504.149z"
                        />
                    </Svg>
                </View>

                <Text marginT-1 marginL-5 numberOfLines={1} ellipsizeMode="tail">
                    {publication.authorships.map(author => author.display_name).join(', ')}
                </Text>
            </View>

            <View>
                <View style={[styles.abstract, { flex: 1 }]}>
                    <Text
                        numberOfLines={2}
                        ellipsizeMode="tail"
                    >
                        {publication.abstract}
                    </Text>
                </View>
                <View marginT-5 row spread>
                    <TouchableOpacity onPress={() => setExpanded(true)}>
                        <View row centerV>
                            <Text marginR-5 marginB-1 style={{
                                color: "#0e5f9c",
                                fontFamily: 'SourceSansProSemiBold',
                            }}>Read more</Text>
                            <Icon name="arrow-ios-forward-outline" width={16} height={16} fill="#0e5f9c" />
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        </Card>

        {expanded && <CustomModal
            title="Paper Details"
            width={720}
            visible={expanded}
            onClose={() => {
                setExpanded(false);
            }}
            drawer={true}
        >
            <View paddingH-20>
                <View padding-5>
                    <PaperDetails publication={publication} />
                </View>
            </View>
        </CustomModal>}

        {showAddToLibrary && <CustomModal
            visible={showAddToLibrary}
            onClose={() => {
                setShowAddToLibrary(false);
            }}
            // title="Add to Library"
            width={isDesktop ? 500 : "100%"}
            drawer={false}
        >
            <View>
                <Text marginB-20 style={[styles.title, { fontSize: 16 }]}>Select Folder</Text>

                {folders.map((folder, index) => <TouchableOpacity key={index} onPress={async () => {
                    const res = await api.customRoute("add-to-folder", {
                        folder_id: folder._id,
                        paper_details: publication,
                        paper_id: publication.id,
                    });

                    if (res.message) {
                        if (Platform.OS === 'web') {
                            message.success(res.message);
                        } else {
                            Alert.alert(res.message);
                        }
                    }

                    if (res.success) {
                        getSavedPaperIds();
                        setShowAddToLibrary(false);
                    }
                }}
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 10,
                        borderBottomWidth: 1,
                        borderBottomColor: "#d7e0e6",
                    }}>
                    <Text>{folder.name}</Text>
                    <Icon name="chevron-right" width={16} height={16} fill="#20303c" />
                </TouchableOpacity>)}

                <View marginT-20>

                    <View marginB-20 style={{
                        position: "relative",
                        width: "100%",
                    }}>
                        <View center>
                            <View style={{
                                backgroundColor: "white",
                                marginBottom: 10,
                                marginTop: 20,
                                paddingHorizontal: 20,
                            }}>
                                <Text style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: "#20303c",
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                    letterSpacing: 2,
                                }}>
                                    OR
                                </Text>
                            </View>
                        </View>

                        <View style={{
                            width: "100%",
                            height: 1,
                            backgroundColor: "#20303c",
                            position: "absolute",
                            top: 28,
                            left: 0,
                            zIndex: -1,
                            opacity: 0.05,
                        }}></View>
                    </View>

                    <View marginB-10 style={{
                        width: "100%",
                    }}>
                        <CustomTextField
                            placeholder="Enter folder name"
                            value={folderName}
                            onChange={(value) => {
                                setFolderName(value);
                            }} />
                    </View>

                    <PrimaryButton
                        label="Create New Folder"
                        onPress={async () => {
                            if (!folderName) {
                                if (Platform.OS === 'web') {
                                    message.success("Please enter folder name");
                                } else {
                                    Alert.alert("Please enter folder name");
                                }
                                return;
                            }

                            let res = await api.customRoute("create-folder", {
                                folder_name: folderName,
                                email_id: email
                            });

                            getFolders();
                            setFolderName("");
                        }}
                    />
                </View>
            </View>
        </CustomModal>}

        {showCiteModal && <CustomModal
            visible={showCiteModal}
            onClose={() => {
                setShowCiteModal(false);
            }}
            title="Cite"
            width={isDesktop ? 720 : "100%"}
            drawer={false}
        >
            <CitationComponent citationData={publication} />
        </CustomModal>}


    </>);
};

const styles = StyleSheet.create({
    card: {
        padding: 16,
        marginVertical: 8,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'top',
        marginBottom: 8,
    },
    badge: {
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 12,
        marginRight: 8,
    },
    badgeText: {
        ...Typography.text70,
        color: Colors.white,
        fontSize: 14,
        letterSpacing: 0.5,
        fontFamily: 'SourceSansProSemiBold',
    },
    titleContainer: {
        flex: 1,
    },
    title: {
        ...Typography.text60,
        fontWeight: 'bold',
    },
    details: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    year: {
        ...Typography.text80,
    },
    type: {
        ...Typography.text80,
    },
    citations: {
        ...Typography.text80,
        fontFamily: 'SourceSansProSemiBold',
    },
    authors: {
        marginBottom: 8,
    },
    abstract: {
        marginVertical: 8,
    },
    topics: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 8,
    },
    topicButton: {
        backgroundColor: "rgb(11, 126 ,212)",
        borderRadius: 16,
        paddingHorizontal: 16,
        paddingVertical: 2,
        marginRight: 8,
        marginBottom: 8,
        paddingBottom: 4,
        fontFamily: 'SourceSansProSemiBold',
        letterSpacing: 0.5,
        fontSize: 11
    },
    topicText: {
        ...Typography.text80,
        color: Colors.white,
    },
});

export function PaperDetails({ publication }) {
    return <View centerH style={{
        width: "100%"
    }}>
        <View style={{
            width: "100%",
        }}>
            {Label({ label: "Title" })}
            <Text>{publication.title}</Text>
        </View>
        <View marginT-10 style={{
            width: "100%",
        }}>
            {Label({ label: "Authors" })}
            <Text>{publication.authorships.map((author) => author.display_name).join(", ")}</Text>
        </View>
        <View row style={{
            flexWrap: 'wrap',
            width: "100%",
        }}>
            <View marginT-10 style={{
                width: "20%",
            }}>
                {Label({ label: "Publication Year" })}
                <Text>{publication.publication_year}</Text>
            </View>
            <View marginT-10 style={{
                width: "20%",
            }}>
                {Label({ label: "Paper Type" })}
                <Text>{formatTypeCrossref(publication.type_crossref)}</Text>
            </View>
            <View marginT-10 style={{
                width: "20%",
            }}>
                {Label({ label: "Cited By" })}
                <Text>{publication.cited_by_count}</Text>
            </View>
            <View marginT-10 style={{
                width: "20%",
            }}>
                {Label({ label: "Cites" })}
                <Text>{publication.referenced_works?.length || 0}</Text>
            </View>
            <View marginT-10 style={{
                width: "20%",
            }}>
                {Label({ label: "Open Access Status" })}
                <Text>{publication.oa_status}</Text>
            </View>
        </View>

        <View marginT-10 style={{
            width: "100%",
        }}>
            {Label({ label: "Source" })}
            <Text>{publication.primary_location?.source?.display_name || "N/A"}</Text>
        </View>

        <View marginT-10 style={{
            width: "100%",
        }}>
            {Label({ label: "Abstract" })}
            <Text>{publication.abstract}</Text>
        </View>

        {publication.topics && <View marginT-10 style={{
            width: "100%",
        }}>
            {Label({ label: "Topics" })}
            <View style={styles.topics}>

                {publication.topics.map((topic, index) => <View key={index} style={{
                    backgroundColor: "#e9e9e9",
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 4,
                    marginRight: 5,
                    marginBottom: 5,
                }}>
                    <Text
                        style={{
                            fontFamily: 'SourceSansProSemiBold',
                        }}
                    >{topic.display_name}</Text>
                </View>
                )}
            </View>
        </View>}
    </View>
}

