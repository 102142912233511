import React, { useEffect, useState } from 'react';
import { View, Text, Button, Colors, TextField, Card } from 'react-native-ui-lib';
import { StyleSheet } from 'react-native';
import { CustomNumberInput, PrimaryButton } from '../../../../../mycomponents/DynamicForm';
import { api } from '../../../../../services/api';
import axios from 'axios';
import { GlobalMasterStore } from '../../../../../models/PermissionStore';

const WalletTopup = ({ onTopup }) => {
    const [amount, setAmount] = useState('50');
    const predefinedAmounts = ['5', '10', '15', '20', '50', '100', '200', '500'];
    const email = GlobalMasterStore((state) => state.globalData.email);

    useEffect(() => {
        if (document.getElementById('razorpay-script')) {
            return;
        }

        const script = document.createElement('script')
        script.id = 'razorpay-script'
        script.src = 'https://checkout.razorpay.com/v1/checkout.js'

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    const handleTopup = async () => {
        try {
            const response = await api.customRoute('shop/generateOrderIdRazorPay', {
                amount: parseFloat(amount) * 100 * 85,
                currency: 'INR',
            });
            console.log('PaymentInitate', response)
            const data = response.data;

            const options = {
                key: data.key_id,
                currency: 'INR',
                amount: parseFloat(amount) * 100 * 85, // Convert to INR
                order_id: data.id,
                handler: function (response) {
                    axios.post('https://researchist-new-api.inkapps.io/add-points-to-email-by-password-post', {
                        email: email,
                        points: amount,
                        increase: true,
                        password: '4375rjgdofn4uhf4978',
                    }).then(() => {
                        window.location.reload();
                    });
                },
            };

            const rzp = new Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error('Error initiating payment:', error);
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.card}>
                <CustomNumberInput
                    label="Enter amount"
                    value={amount}
                    onChange={setAmount}
                />

                <View marginT-10 style={styles.amountGrid} m>
                    {predefinedAmounts.map((preset) => (
                        <Button
                            key={preset}
                            label={`$${preset}`}
                            size="small"
                            outline={amount !== preset}
                            outlineColor={'#000000'}
                            backgroundColor={amount === preset ? '#000000' : Colors.white}
                            onPress={() => setAmount(preset)}
                            style={styles.amountButton}
                            labelStyle={styles.amountButtonLabel}
                        />
                    ))}
                </View>

                <PrimaryButton
                    label={`Top up $${amount}`}
                    onPress={handleTopup}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        width: '100%',
        maxWidth: '100%',
        marginTop: 20,
    },
    title: {
        marginBottom: 20,
        textAlign: 'center',
    },
    input: {
        borderBottomWidth: 1,
        borderColor: '#000000',
        marginBottom: 20,
    },
    amountGrid: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    amountButton: {
        width: '23%',
        marginBottom: 10,
    },
    amountButtonLabel: {
        fontSize: 14,
        fontFamily: 'SourceSansProSemibold',
    },
    topupButton: {
        marginTop: 20,
    },
});

export default WalletTopup;