// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, TextInput, ActivityIndicator, StyleSheet } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView, Typography, Avatar } from 'react-native-ui-lib';
import DynamicForm, { CustomDatePicker, CustomDropDown, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App, Dropdown, Segmented, Slider, DatePicker, Table } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"
import moment from "moment"
import { set } from "mobx"
import axios from "axios"
import { Path, Svg } from "react-native-svg"
import { FlashList } from "@shopify/flash-list"
import dayjs from "dayjs"
import { Realtime } from 'ably';
import { RND } from "../../../../../models/accountancy/transaction"
import { HeaderResearch } from "./PaperListingScreen"
const { RangePicker } = DatePicker;

const isDesktop = Dimensions.get('window').width >= 768;

const UserPoints = observer(function UserPoints(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);
    const { route } = props;
    let queryParams = {};
    const [search, setSearch] = useState('');

    let params = route?.params ? route?.params : useParams();

    if (Platform.OS === 'web') {
        const fullUrl = new URL(window.location.href).search;
        const queryStringStart = fullUrl.indexOf('?');
        const queryString = fullUrl.substring(queryStringStart);

        const queries = queryString.substring(1);

        queries.split('&').forEach(pair => {
            const [key, value] = pair.split('=');
            queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
        });

        if (!params.ignoreQueryParams) {
            params = { ...params, ...queryParams }
        }
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };


    const email = GlobalMasterStore((state) => state.globalData.email);
    const [users, setUsers] = useState([]);
    const [showAddPointsModal, setShowAddPointsModal] = useState(false);
    const [newPoints, setNewPoints] = useState(0);
    const [selectedUserEmail, setSelectedUserEmail] = useState('');
    const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState(null);

    const getUsers = async () => {
        const response = await axios.get('https://researchist-new-api.inkapps.io/get-all-users-to-add-points', {

        });

        setUsers(response.data);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',
            render: (text, record) => (
                <Text>{RND(record.points || 0)}</Text>
            ),
        },
        {
            title: 'Subscription Expiry Date',
            dataIndex: 'points',
            key: 'points',
            render: (text, record) => (
                <Text>{record.subscription_expiry_date ? moment(record.subscription_expiry_date).format('DD/MM/YYYY') : 'N/A'}</Text>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <SecondaryButton
                    label="Update"
                    onPress={() => {
                        setSelectedUserEmail(record.email);
                        setSubscriptionExpiryDate(new Date(record.subscription_expiry_date));
                        setNewPoints(record.points);
                        setShowAddPointsModal(true);
                    }}
                />
            ),
        },
    ];

    useEffect(() => {
        getUsers();
    }, []);

    return (<View center style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View centerH flexG marginB-20
                    style={{
                        width: "100%",
                        height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                    }}
                >
                    <HeaderResearch navigationFn={navigationFn} showPaperCount={true} />

                    <View marginT-10 padding-20 style={{
                        width: "100%",
                        maxWidth: '100%',
                    }}>

                        <View marginB-10>
                            <CustomTextField
                                value={search}
                                onChange={(value) => setSearch(value)}
                                placeholder="Search by name or email"
                            />
                        </View>

                        <Table columns={columns}
                            dataSource={search ? users.filter(user => user?.name?.toLowerCase().includes(search.toLowerCase()) || user?.email?.toLowerCase().includes(search.toLowerCase())) : users}
                            style={{
                                width: "100%",
                            }}
                        />
                    </View>
                </View>

                <CustomModal
                    title="Add Points"
                    visible={showAddPointsModal}
                    onCancel={() => setShowAddPointsModal(false)}
                >

                    <View marginB-20>
                        {Label({ label: 'Subscription Expiry Date' })}
                        <CustomDatePicker
                            value={subscriptionExpiryDate}
                            onChange={(value) => setSubscriptionExpiryDate(value)}
                        />
                    </View>


                    <View marginB-20>
                        {Label({ label: 'Points' })}
                        <CustomNumberInput
                            value={newPoints}
                            onChange={(value) => setNewPoints(value)}
                        />
                    </View>

                    <View row>
                        <PrimaryButton
                            label="Add Points"
                            onPress={() => {
                                axios.post('https://researchist-new-api.inkapps.io/add-points-to-email-by-password-post', {
                                    email: selectedUserEmail,
                                    points: newPoints,
                                    password: '4375rjgdofn4uhf4978',
                                    subscription_expiry_date: subscriptionExpiryDate,
                                }).then(() => {
                                    getUsers();
                                    setShowAddPointsModal(false);
                                });
                            }}
                        />

                        <View marginL-5>
                            <SecondaryButton
                                label="Cancel"
                                onPress={() => setShowAddPointsModal(false)}
                            />
                        </View>
                    </View>

                </CustomModal>

            </KeyboardAwareScrollView>
        </SafeAreaView>
    </View >)
})

export default UserPoints

const $container = {
    flex: 1,
}

const styles = StyleSheet.create({
    card: {
        padding: 16,
        marginVertical: 8,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'top',
        marginBottom: 8,
    },
    badge: {
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 12,
        marginRight: 8,
    },
    badgeText: {
        ...Typography.text70,
        color: Colors.white,
        fontSize: 14,
        letterSpacing: 0.5,
        fontFamily: 'SourceSansProSemiBold',
    },
    titleContainer: {
        flex: 1,
    },
    title: {
        ...Typography.text60,
        fontWeight: 'bold',
    },
    details: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    year: {
        ...Typography.text80,
    },
    type: {
        ...Typography.text80,
    },
    citations: {
        ...Typography.text80,
        fontFamily: 'SourceSansProSemiBold',
    },
    authors: {
        marginBottom: 8,
    },
    abstract: {
        marginVertical: 8,
    },
    topics: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 8,
    },
    topicButton: {
        backgroundColor: "rgb(11, 126 ,212)",
        borderRadius: 16,
        paddingHorizontal: 16,
        paddingVertical: 2,
        marginRight: 8,
        marginBottom: 8,
        paddingBottom: 4,
        fontFamily: 'SourceSansProSemiBold',
        letterSpacing: 0.5,
        fontSize: 11
    },
    topicText: {
        ...Typography.text80,
        color: Colors.white,
    },
});



